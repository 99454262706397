import Vue from "vue";
import Router from "vue-router";
import Container from "@/components/Container.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: Container,
    children: [
      {
        path: "/",
        name: "index",
        meta: { title: "正在跳转" },
        component: () => import("./view/user"),
      },
      {
        path: "user",
        component: Container,
        children: [
          {
            path: "/",
            name: "userIndex",
            meta: { title: "用户中心" },
            component: () => import("./view/user"),
          },
          {
            path: "profile",
            name: "profile",
            meta: { title: "用户资料" },
            component: () => import("./view/user/profile"),
          },
        ],
      },
      {
        name: "oauth",
        path: "/oauth",
        component: () => import("./view/oauth"),
        meta: { title: "微信授权" },
      },
      {
        name: "bind",
        path: "/bind",
        component: () => import("./view/bind"),
        meta: { title: "注册健康卡" },
      },
      {
        name: "upOwner",
        path: "/upOwner",
        component: () => import("./view/bind/upOwner"),
        meta: { title: "主卡升级为健康卡" },
      },
      {
        name: "UA",
        path: "/UA",
        component: () => import("./components/Manager/UserAgree.vue"),
        meta: { title: "用户协议" },
      },

      /**
       * 个人信息
       */
      {
        name: "ulist",
        path: "/ulist",
        component: () => import("./components/Manager/CardManage.vue"),
        meta: { title: "就诊卡成员管理" },
      },
      {
        name: "uadd",
        path: "/uadd",
        component: () => import("./components/Manager/CardAdd.vue"),
        meta: { title: "成员添加" },
      },
      {
        name: "uinfo",
        path: "/uinfo",
        component: () => import("./components/Manager/MyInfo.vue"),
        meta: { title: "成员信息" },
      },

      /**
       * 挂号就诊
       */
      {
        name: "CardL",
        path: "/CardL",
        component: () => import("./components/Reg/Cardlist.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "DepS",
        path: "/DepS",
        component: () => import("./components/Reg/DepSelect"),
        meta: { title: "科室列表" },
      },
      {
        name: "DocL",
        path: "/DocL",
        component: () => import("./components/Reg/Doclist"),
        meta: { title: "医生列表" },
      },
      {
        name: "QueI",
        path: "/QueI",
        component: () => import("./components/Reg/QueryInfo"),
        meta: { title: "信息确认" },
      },
      {
        name: "Wait",
        path: "/Wait",
        component: () => import("./components/Reg/Waiting"),
        meta: { title: "挂号就诊" },
      },
      {
        name: "RegL",
        path: "/RegL",
        component: () => import("./components/Reg/RegistList"),
        meta: { title: "待就诊列表" },
      },
      {
        name: "CardLs",
        path: "/CardLs",
        component: () => import("./components/Reg/Cardlists"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "RegIT",
        path: "/RegIT",
        component: () => import("./components/Reg/RegistInfoT"),
        meta: { title: "就诊信息" },
      },
      {
        name: "RegIF",
        path: "/RegIF",
        component: () => import("./components/Reg/RegistInfoF"),
        meta: { title: "就诊信息" },
      },

      /**
       * 门诊缴费
       */
      {
        name: "FCardL",
        path: "/FCardL",
        component: () => import("./components/medicare/MCardlist.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "PayL",
        path: "/PayL",
        component: () => import("./components/Fee/PayList.vue"),
        meta: { title: "待缴费列表" },
      },
      {
        name: "FWait",
        path: "/FWait",
        component: () => import("./components/Fee/FWaiting"),
        meta: { title: "门诊缴费" },
      },
      {
        name: "OrdeI",
        path: "/OrdeI",
        component: () => import("./components/Fee/OrderInfo.vue"),
        meta: { title: "订单详情" },
      },
      {
        name: "FPayS",
        path: "/FPayS",
        component: () => import("./components/Fee/FSuccess.vue"),
        meta: { title: "支付成功" },
      },

      /**
       * 检验报告
       */
      {
        name: "RQuery",
        path: "/RQuery",
        component: () => import("./components/Report/RepQuery.vue"),
        meta: { title: "报告查询" },
      },
      {
        name: "RpCardL",
        path: "/RpCardL",
        component: () => import("./components/Report/RCardList.vue"),
        meta: { title: "检验报告" },
      },
      {
        name: "RepL",
        path: "/RepL",
        component: () => import("./components/Report/ReportList.vue"),
        meta: { title: "检验报告列表" },
      },
      {
        name: "RepLI",
        path: "/RepLI",
        component: () => import("./components/Report/RepLisInfo.vue"),
        meta: { title: "LIS报告详情" },
      },
      {
        name: "RepPI",
        path: "/RepPI",
        component: () => import("./components/Report/RepPacsInfo.vue"),
        meta: { title: "PACS报告详情" },
      },
      {
        name: "VpCardL",
        path: "/VpCardL",
        component: () => import("./components/Report/VCardList.vue"),
        meta: { title: "影像报告" },
      },

      /**
       * 自助查询
       */
      {
        name: "Query",
        path: "/Query",
        component: () => import("./components/Query/Query.vue"),
        meta: { title: "自助查询" },
      },
      {
        name: "QRCardL",
        path: "/QRCardL",
        component: () => import("./components/Query/RCardList.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "QRegL",
        path: "/QRegL",
        component: () => import("./components/Query/QRegList.vue"),
        meta: { title: "挂号记录" },
      },
      {
        name: "QRegIF",
        path: "/QRegIF",
        component: () => import("./components/Query/QRegInfoF.vue"),
        meta: { title: "挂号详情" },
      },
      {
        name: 'mpaycardl',
        path: '/mpaycardl',
        component: () => import('./components/Query/mpaycardl.vue'),
        meta: { title: '现在成员'}
      },
      {
        name: 'mpaylist',
        path: '/mpaylist',
        component: () => import('./components/Query/mpaylist.vue'),
        meta: { title: '医保缴费查询'}
      },
      {
        name: 'MorderInfos',
        path: '/MorderInfos',
        component: () => import('./components/Query/MorderInfo.vue'),
        meta: { title: '医保缴费详情'}
      },
      {
        name: "QRegIT",
        path: "/QRegIT",
        component: () => import("./components/Query/QRegInfoT.vue"),
        meta: { title: "挂号详情" },
      },
      {
        name: "QFCardL",
        path: "/QFCardL",
        component: () => import("./components/Query/QFCardList.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "QFOrdeI",
        path: "/QFOrdeI",
        component: () => import("./components/Query/QFOrderInfo.vue"),
        meta: { title: "缴费记录" },
      },
      {
        name: "QFOrdeIF",
        path: "/QFOrdeIF",
        component: () => import("./components/Query/QFOrderInfoF.vue"),
        meta: { title: "缴费记录" },
      },
      {
        name: "QFOrdeIT",
        path: "/QFOrdeIT",
        component: () => import("./components/Query/QFOrderInfoT.vue"),
        meta: { title: "缴费记录" },
      },
      {
        name: "QFPayS",
        path: "/QFPayS",
        component: () => import("./components/Query/QFSuccess.vue"),
        meta: { title: "支付成功" },
      },
      {
        name: "NoDrugs",
        path: "/NoDrugs",
        component: () => import("./components/Query/NoDrugs.vue"),
        meta: { title: "项目查询" },
      },
      {
        name: "Drugs",
        path: "/Drugs",
        component: () => import("./components/Query/Drugs.vue"),
        meta: { title: "药品查询" },
      },
      {
        name: "QHCardL",
        path: "/QHCardL",
        component: () => import("./components/Query/QHCardList.vue"),
        meta: { title: "住院查询" },
      },
      {
        name: "QHInfo",
        path: "/QHInfo",
        component: () => import("./components/Query/QHospInfo.vue"),
        meta: { title: "住院查询" },
      },
      {
        name: "QHFeeL",
        path: "/QHFeeL",
        component: () => import("./components/Query/QHFeeList.vue"),
        meta: { title: "费用清单" },
      },
      {
        name: "QHFeeD",
        path: "/QHFeeD",
        component: () => import("./components/Query/QHFeeDetailed.vue"),
        meta: { title: "费用小结" },
      },
      {
        name: "QHFeeR",
        path: "/QHFeeR",
        component: () => import("./components/Query/QHFeeRecord.vue"),
        meta: { title: "预交记录" },
      },
      {
        name: "qcCardL",
        path: "/qcCardL",
        component: () => import("./components/Query/QCcardlist.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "qCorsFL",
        path: "/qCorsFL",
        component: () => import("./components/Query/QcorsFeeList"),
        meta: { title: "核酸检测开单记录" },
      },
      /**
       * 医院微网
       */
      {
        name: "HospI",
        path: "/HospI",
        component: () => import("./components/Intr/HospitalInfo.vue"),
        meta: { title: "医院简介" },
      },
      {
        name: "InDepL",
        path: "/InDepL",
        component: () => import("./components/Intr/InDepList.vue"),
        meta: { title: "科室列表" },
      },
      {
        name: "InDepI",
        path: "/InDepI",
        component: () => import("./components/Intr/DepartmentInfo.vue"),
        meta: { title: "科室简介" },
      },
      {
        name: "InDocL",
        path: "/InDocL",
        component: () => import("./components/Intr/InDoclist.vue"),
        meta: { title: "科室列表" },
      },
      {
        name: "InDocLs",
        path: "/InDocLs",
        component: () => import("./components/Intr/InDoclists.vue"),
        meta: { title: "科室医生" },
      },
      {
        name: "InDocI",
        path: "/InDocI",
        component: () => import("./components/Intr/DoctorInfo.vue"),
        meta: { title: "医生简介" },
      },

      {
        name: "InNews",
        path: "/InNews",
        component: () => import("./components/Intr/InNews.vue"),
        meta: { title: "新闻动态" },
      },
      {
        name: "NewsI",
        path: "/NewsI",
        component: () => import("./components/Intr/NewsInfo.vue"),
        meta: { title: "动态详情" },
      },
      {
        name: "Navg",
        path: "/Navg",
        component: () => import("./components/Intr/Navigation.vue"),
        meta: { title: "来院导航" },
      },

      /**
       * 住院预缴
       */
      {
        name: "HCardL",
        path: "/HCardL",
        component: () => import("./components/Hosp/HCardList.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "HInfo",
        path: "/HInfo",
        component: () => import("./components/Hosp/HospInfo.vue"),
        meta: { title: "住院预交" },
      },
      {
        name: "HFeeL",
        path: "/HFeeL",
        component: () => import("./components/Hosp/HFeeList.vue"),
        meta: { title: "费用清单" },
      },
      {
        name: "HFeeD",
        path: "/HFeeD",
        component: () => import("./components/Hosp/HFeeDetailed.vue"),
        meta: { title: "费用小结" },
      },
      {
        name: "HFeeR",
        path: "/HFeeR",
        component: () => import("./components/Hosp/HFeeRecord.vue"),
        meta: { title: "预交记录" },
      },
      {
        name: "HWait",
        path: "/HWait",
        component: () => import("./components/Hosp/HWaiting"),
        meta: { title: "住院预交" },
      },

      /**
       * 智能导诊
       */
      {
        name: "ICardL",
        path: "/ICardL",
        component: () => import("./components/IntelGuid/InterlCardList.vue"),
        meta: { title: "智能导诊" },
      },
      {
        name: "IDepList",
        path: "/IDepList",
        component: () => import("./components/IntelGuid/IntelDepList.vue"),
        meta: { title: "科室列表" },
      },
      {
        name: "ICharaImg",
        path: "/ICharaImg",
        component: () => import("./components/IntelGuid/IntelCharaImg.vue"),
        meta: { title: "人物图像" },
      },
      {
        name: "ITestResult",
        path: "/ITestResult",
        component: () => import("./components/IntelGuid/IntelTestResult.vue"),
        meta: { title: "自测结果" },
      },
      {
        name: "IDocList",
        path: "/IDocList",
        component: () => import("./components/IntelGuid/IntelDocList.vue"),
        meta: { title: "医生列表" },
      },
      {
        name: "IQueryInfo",
        path: "/IQueryInfo",
        component: () => import("./components/IntelGuid/IntelQueryInfo.vue"),
        meta: { title: "挂号信息" },
      },
      {
        name: "IntelGuidl",
        path: "/IntelGuidl",
        component: () => import("./components/IntelGuid/IntelGuidl.vue"),
        meta: { title: "症状自测" },
      },
      {
        name: "IWaiting",
        path: "/IWaiting",
        component: () => import("./components/IntelGuid/IntelWaiting.vue"),
        meta: { title: "支付成功" },
      },

      /**
       * 自助开单
       */
      {
        name: "CcardL",
        path: "/CcardL",
        component: () => import("./components/corsFee/Ccardlist.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "corsF",
        path: "/corsF",
        component: () => import("./components/corsFee/corsFeeInfo.vue"),
        meta: { title: "核酸自助开单" },
      },
      {
        name: "CWait",
        path: "/CWait",
        component: () => import("./components/corsFee/CWaiting.vue"),
        meta: { title: "支付成功" },
      },
      {
        name: "cCFL",
        path: "/cCFL",
        component: () => import("./components/corsFee/corsFeeList.vue"),
        meta: { title: "核酸检测开单记录" },
      },

      /**电子健康卡 */
      {
        name: "Healcard",
        path: "/healcard",
        component: () => import("./components/Healthcard/healthCard.vue"),
        meta: { title: "健康卡列表" },
      },
      {
        name: "HealCardM",
        path: "/healcardM",
        component: () => import("./components/Healthcard/healthCardMeniu.vue"),
        meta: { title: "添加健康卡" },
      },
      {
        name: "HealCI",
        path: "/HealCI",
        component: () => import("./components/Healthcard/HealthcardInfo.vue"),
        meta: { title: "升级电子健康卡信息补全" },
      },
      {
        name: "HcNewI",
        path: "/HcNewI",
        component: () =>
          import("./components/Healthcard/HealthcardNewInfo.vue"),
        meta: { title: "注册新的电子健康卡" },
      },

      {
        name: "HcCode",
        path: "/HcCode",
        component: () => import("./components/Healthcard/HCTextcode.vue"),
        meta: { title: "电子健康卡信息" },
      },
      /**
       * 医保缴费
       */
      {
        name: "MCardL",
        path: "/MCardL",
        component: () => import("./components/medicare/MCardlist.vue"),
        meta: { title: "健康卡选择" },
      },
      {
        name: "MPayL",
        path: "/MPayL",
        component: () => import("./components/medicare/MPayList.vue"),
        meta: { title: "医保缴费列表" },
      },
      {
        name: "MPay",
        path: "/MPay",
        component: () => import("./components/medicare/MPay.vue"),
        meta: { title: "门诊缴费" },
      },

      // {
      //   name: 'MWait',
      //   path: '/MWait',
      //   component: () => import('./components/medicare/MWaiting'),
      //   meta: { title: '缴费完成'}
      // },
      {
        name: "medicarePay",
        path: "/medicarePay",
        component: () => import("./components/medicare/medicarePay/index.vue"),
        meta: { title: "医保待缴费" },
      },
      {
        name: "YBcorsPay",
        path: "/YBcors",
        component: () => import("./components/corsFee/YBcorsPay/index.vue"),
        meta: { title: "核酸医保缴费" },
      },

      // {
      //   name: 'MordeI',
      //   path: '/MordeI',
      //   component: () => import('./components/medicare/MorderInfo.vue'),
      //   meta: { title: '订单详情'}
      // },
      {
        name: "MPayS",
        path: "/MPayS",
        component: () => import("./components/medicare/MSuccess.vue"),
        meta: { title: "医保支付成功" },
      },

      /**
       * 支付成功
       */
      {
        name: "PayS",
        path: "/PayS",
        component: () => import("./components/Pay/Success.vue"),
        meta: { title: "支付成功" },
      },

      /**
       * 错误页面
       */
      {
        name: "error",
        path: "/error",
        component: Container,
        children: [
          {
            path: "/404",
            name: "404",
            meta: { title: "404错误，找不到页面" },
            component: () => import("./view/error/404.vue"),
          },
          {
            path: "/500",
            name: "500",
            meta: { title: "500错误，服务器异常" },
            component: () => import("./view/error/500.vue"),
          },
        ],
      },
    ],
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
