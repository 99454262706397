import { router } from "./route";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { Dialog, Toast } from "vant";
import ajax from "./lib/ajax";
import Util from "./lib/util";

const whiteList = [
  "/oauth",
  "/bind",
  "/upOwner",
  // "/Navg",
  "/UA",

  // /**
  //  * 信息绑定
  //  */
  // "/ulist",
  // "/uadd",
  // "/uinfo",
  // 核酸记录
  "/cCFL",
  /**
   * 挂号就诊
   */
  // "CardL",
  "/DepS",
  "/DocL",
  "/QueI",
  "/Wait",
  // "CardLs",
  "/RegL",
  "/RegIT",
  "/RegIF",

  /**
   * 智能导诊
   */
  // "/ICardL",
  "/IDepList",
  "/ICharaImg",
  "/ITestResult",
  "/IQueryInfo",
  "/IntelGuidl",
  "/IWaiting",
  "/IDocList",

  /**
   * 门诊缴费
   */
  // "FCardL",
  "/PayL",
  "/OrdeI",
  "/FPayS",

  /**
   * 检验报告
   */
  // "/RpCardL",
  "/RepL",
  "/RepLI",
  "/RepPI",

  /**
   * 自助查询
   */
  // "Query",
  // "/QRCardL",
  "/QRegL",
  "/QRegIF",
  "/QRegIT",
  // "/QFCardL",
  "/QFOrdeI",
  "/QFOrdeIF",
  "/QFOrdeIT",
  "/QFPayS",
  "/NoDrugs",
  "/Drugs",
  /**
   * 医院微网
   */
  "/HospI",
  "/InDepL",
  "/InDepI",
  "/InDocL",
  "/InDocLs",
  "/InDocI",
  "/InNews",
  "/NewsI",

  /**
   * 支付成功轮询
   */
  "/PayS",

  /**
   * 错误页面
   */
  "/error/404",
  "/error/500",
]; // no redirect whitelist
NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // 开始进度
  NProgress.start();

  /**禁用页面缓存 */
  var { query } = to;
  if (!query["_random"]) {
    next({ ...to, query: { ...query, _random: `${Math.random()}` } });
  }

  /**白名单里的直接过 */
  if (whiteList.indexOf(to.path) !== -1) {
    next();
    return;
  }

  //还原微信登录
  if (store.getters["wechat/openid"] === "") {
    await store.dispatch("wechat/restore");
  }

  // TODO:获取本地OpenId
  let openId = "";
  if (process.env.NODE_ENV === "development") {
    //openId = "iamadmin"; //测试用OpenId
    openId = store.getters["wechat/openid"];
    // store.dispatch("wechat/setOpenIdForDevelopment", openId); //更新状态中的openId
    // console.log(openId,store.dispatch("wechat/setOpenIdForDevelopment", openId));
  } else {
    openId = store.getters["wechat/openid"];
  }

  if (openId) {
    //有OpenId
    //登录状态
    let password = "TS2020lwh" || "123qwe";
    await ajax
      .post("/api/TokenAuth/Authenticate", {
        userNameOrEmailAddress: "admin",
        password: password,
        // password:"1"
      })
      .then(async (rep) => {
        var tokenExpireDate = undefined;
        // console.log(rep.data.result.accessToken);
        // sessionStorage.setItem("authToken",rep.data.result.accessToken);
        Util.abp.auth.setToken(rep.data.result.accessToken, tokenExpireDate);
      });
    // let token = Util.abp.auth.getToken();
    // alert(JSON.stringify(token));
    let login = "";
    // alert(openId);
    if (!login) {
      await ajax
        .post("/Api/LoginService/LoginByWeChat?input=" + openId)
        .then((res) => {
          login = res.data.result;
          store.commit("store/setOwneridcard", login.idCard);
          // store.commit('store/setUserId', res.data.result.userId);
          if (login.result == 0) {
            sessionStorage.setItem("openid", openId);
            if (login.barCode == "" || login.barCode == null) {
              Toast("您的主卡还未升级为健康卡，请先将主卡升级为健康卡！");
              next({ path: "/upOwner", replace: true });
              return;
            }
          }
          if (login.result == 1) {
            sessionStorage.setItem("openid", openId);
            Toast("对不起，您还未绑定健康卡或还未升级为健康卡！");
            next({ path: "/bind", query: to.query, replace: true });
            return;
          }
        });
      console.log("ssssssssssss");
      // 有OpenId，没有登录

      console.log(login);

    }

    //已经登录
    //不能去oauth页面
    if (to.path === "/oauth") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    //没有OpenId
    //不在白名单里面
    var p = to.path,
      q = window.location.search,
      url = encodeURIComponent(`${p}${q}`);
    next({ path: `/oauth?redirect=${url}`, replace: true });
  }
});

// 结束进度
router.afterEach(() => {
  NProgress.done();
});

//导航出错
router.onError((err) => {
  NProgress.done();
  Dialog.alert({ message: err.message });
});
