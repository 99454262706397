import axios from 'axios';
import appconst from '../../lib/appconst';

const state = {
    openid: "",
    nickname: "",
    sex: 0,
    province: "",
    city: "",
    country: "",
    headimgurl: "",
    privilege: [],
    unionid: ""
  };

  const getters = {
    openid: (state) => state.openid
  };

  const actions = {
    async oauth({ dispatch, commit }, accessKey) {
        let authResultUrl = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/Result`;
        await axios.get(`${authResultUrl}?accessKey=${accessKey}`)
        .then(rep => {
          console.log(JSON.stringify(rep.data.result));
          let info = rep.data.result;
          commit("SAVE_USER", info);
          dispatch("save");
        });
    },
    async save({ state }) {
      try {
        const info = JSON.stringify(state);
        localStorage.setItem("wechat", info);
      } catch (e) {
        console.error(e.message);
      }
    },
    async restore({ commit }) {
      const info = localStorage.getItem("wechat");
      if (info === null) {
        return;
      }
      try {
        const state = JSON.parse(info);
        commit("SAVE_USER", state);
      } catch (e) {
        console.error(e.message);
      }
    },
    setOpenIdForDevelopment({ commit }, openid) {
      commit("SAVE_USER", { openid });
    }
  };
  
  const mutations = {
    SAVE_USER(state, info) {
      Object.assign(state, info);
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };