const AppConsts = {
  userManagement: {
    defaultAdminUserName: "wechat",
  },
  localization: {
    defaultLocalizationSourceName: "FuGongApi",
    // defaultLocalizationSourceName: 'JiangChengApi'
    // defaultLocalizationSourceName: 'ZhenYuanApi'
  },
  authorization: {
    encrptedAuthTokenName: "enc_auth_token",
  },
  appBaseUrl: "http://wx.fgxrmyy.cn/",
  remoteServiceBaseUrl:"https://api.fgxrmyy.cn/",
  
  // remoteServiceBaseUrl: "https://test.fgxrmyy.cn/",
  thirdApiCenterBaseUrl: "https://api.fgxrmyy.cn/",
  wechatAppId: "wxab583b5b776e236b",
  // appBaseUrl: "https://wx.jcxrmyy.top/",
  // remoteServiceBaseUrl:"https://api.jcxrmyy.top/",
  // thirdApiCenterBaseUrl:"https://oauth.jcxrmyy.top/",
  // wechatAppId:"wx40a97371ed7f7a55"
  // appBaseUrl: "http://wx.peszyxrmyy.com/",
  // remoteServiceBaseUrl:"http://api.peszyxrmyy.com/",
  // thirdApiCenterBaseUrl:"http://oauth.peszyxrmyy.com/",
  // wechatAppId:"wxb504284bb6522503"
  // appBaseUrl: "http://localhost:8080/",
  // remoteServiceBaseUrl: "http://192.168.101.101:9001/",
  // thirdApiCenterBaseUrl: "http://apitest.ceres.pub/",
  // wechatAppId: "wxfbc4a7004efe6635"
};
export default AppConsts;
